import { Tab, Tabs } from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import { hasPermission, CONTEXTS, SCOPES } from '../../services/permissions'

export const TheNavBarTabs = ({ pathname }) => {
  const tabPathToIndex = {
    '/arts/search': 0,
    '/arts': 1,
    '/fonts': 2,
    '/users': 3,
  };

  const { role } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(tabPathToIndex[pathname] ?? 0);
  
  const handleTabChange = (_event, newSelectedTab) => setSelectedTab(newSelectedTab);
  const multipleScopes = [SCOPES.canView, SCOPES.canCreate, SCOPES.canEdit, SCOPES.canDelete];

  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="Itens de menu"
      textColor="inherit"
      indicatorColor="secondary"
      TabIndicatorProps={{ style: { background: 'white' } }}
    >
      {hasPermission(role, CONTEXTS.art, SCOPES.canView) ? (
        <Tab label="Pesquisar" component={Link} to="/arts/search" />
      ) : null}
      {hasPermission(role, CONTEXTS.art, multipleScopes) ? (
        <Tab label="Obras" component={Link} to="/arts" />
      ) : null}
      {hasPermission(role, CONTEXTS.font, multipleScopes) ? (
        <Tab label="Fichas Catalográficas" component={Link} to="/fonts" />
      ) : null}
      {hasPermission(role, CONTEXTS.user, multipleScopes) ? (
        <Tab label="Usuários" component={Link} to="/users" />
      ) : null}
    </Tabs>
  );
};
