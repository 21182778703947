import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import {
    Paper,
    AppBar,
    Toolbar,
    Stack,
    TextField,
    Button,
    Typography,
    List,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slide,
    Snackbar,
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Collapse,
    capitalize
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { ResourceListItem } from '../../components/ResourceListItem/ResourceListItem';
import { getArts, deleteArt, getFonts, createArt, getArtId, updateArtId, getFontId } from '../../services/api';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Arts() {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    const [searchString, setSearchString] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [, setHidden] = useState(false);
    const [arts, setArts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [currentDeleteId, setCurrentDeleteId] = useState("");
    const [openSnackDelete, setOpenSnackDelete] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [fonts, setFonts] = useState([]);
    const [errors, setErrors] = useState({});
    const [openSnack, setOpenSnack] = React.useState(false);
    const [openSnackUpdate, setOpenSnackUpdate] = React.useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const fileRef = React.useRef();
    const [currentId, setCurrentId] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("");
    const [font, setFont] = useState("");
    const [selectedFontAttr, setSelectedFontAttr] = useState([]);
    const [form, setForm] = useState({});
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [invalidFile, setInvalidFile] = useState(false);

    const handleSelectFile = (event) => {
        const file = event.target.files[0];
        const validFileType = allowedFileTypes.includes(file.type);
        if (validFileType) {
            setSelectedFile(file);
        } else {
            setInvalidFile(true);
        }
    };

    const verifyErrorCode = (value) => {
        errors.code = null
        setCode(value)
    };

    const verifyTitleCode = (value) => {
        errors.title = null
        setTitle(value)
    };

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });

        if (errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleDeleteArt = async (id, event) => {
        event.preventDefault();
        setCurrentDeleteId("");
        setOpenConfirm(false);
        setLoading(true);

        try {
            await deleteArt(id);
            setLoading(false);
            handleClickSnackDelete();
            fetchArts();

        } catch (err) {
            setLoading(false);
        }
    };

    const fetchArts = async () => {
        try {
            const { data } = await getArts();
            const arts = data;
            setArts(arts);
            if (arts.len === 0) {
                setHidden(false);
            } else {
                setHidden(true);
            }
        } catch (err) {
            setHidden(false);
        }
    };
    const fetchFonts = async () => {
        setLoading(true);
        try {
            const { data } = await getFonts();
            const fonts = data;
            setFonts(fonts);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const findFormErrors = () => {
        const newErrors = {};

        if (!code || code === '') newErrors.code = 'Código obrigatório';

        if (!title || title === '') newErrors.title = 'Título obrigatório';

        if (!font || font === '') newErrors.font = 'Ficha catalográfica obrigatória';

        if (isUpdate) {
            selectedFontAttr.map(attrMapped => {
                if (!attrMapped.value || attrMapped.value === '') {
                    newErrors[attrMapped.name] = `${attrMapped.name.charAt(0).toUpperCase() + attrMapped.name.slice(1)} obrigatório`;
                    console.log(newErrors)
                }
            });
        } else {
            selectedFontAttr.map(attrMapped => {
                if (!form[attrMapped.name] || form[attrMapped.name] === '') {
                    newErrors[attrMapped.name] = `${attrMapped.name.charAt(0).toUpperCase() + attrMapped.name.slice(1)} obrigatório`;
                    console.log(newErrors)
                }
            });
        }

        return newErrors;
    };

    useEffect(() => {
        document.title = 'CEDOMCA | Lista de obras';

        fetchArts().then(() => setLoadingData(false));
    }, []);

    useEffect(() => {
        const filteredArts = arts.filter((art) =>
            art.title.toLowerCase().includes(searchString.toLowerCase()),
        );

        setSearchResult(filteredArts);
    }, [searchString, arts]);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        setCurrentDeleteId("");
    }

    const showConfirmDelete = (index, event) => {
        event.preventDefault();
        setOpenConfirm(true);
        setCurrentDeleteId(index);
    };

    const handleClickSnackDelete = () => {
        setOpenSnackDelete(true);
    };

    const handleCloseSnackDelete = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackDelete(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        fetchFonts();
    };

    const handleClose = () => {
        setCode("");
        setTitle("");
        setFont("");
        setErrors([]);
        setForm({});
        setSelectedFontAttr([]);
        setSelectedFile();
        fileRef.current.value = "";
        setOpen(false);
        setIsUpdate(false);
        setInvalidFile(false);
    };

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleClickSnackUpdate = () => {
        setOpenSnackUpdate(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const setFontandSelectedFont = (value, id) => {
        errors.font = null;
        setFont(value);
        fetchFontId(id);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true);

            const formData = new FormData();
            formData.append('File', selectedFile);
            const attr = []

            Object.entries(form).map(([key, value]) => {
                attr.push({
                    name: key,
                    value: value
                })
            });

            const data = {
                code: code,
                title: title,
                font: font,
                file: selectedFile,
                attributes: attr
            };
            try {
                await createArt(data);
                setLoading(false);
                handleClose();
                handleClickSnack();
                fetchArts();
            } catch (err) {
                setLoading(false);
                console.log(err);
                setShow(true);
                let errorMsg = err.response.data.message.toString();
                let newErrorMsg = errorMsg.replaceAll(',', '\n\n');
                setMessage(newErrorMsg);
                setTimeout(function () {
                    setShow(false);
                    setMessage("");
                }, 7000);
            }
        }
    };

    const fetchArtId = async (id) => {
        try {
            setLoading(true);
            const { data } = await getArtId(id);
            setCode(data.code);
            setTitle(data.title);
            setFont(data.font);
            setSelectedFontAttr(data.attributes);
            setCurrentId(id);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const fetchFontId = async (id) => {
        setLoading(true);
        try {
            const { data } = await getFontId(id);
            setSelectedFontAttr(data.attributes)

            selectedFontAttr.map(attr => (
                setField(attr.name, "")
            ))
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const handleUpdateArt = (id, event) => {
        event.preventDefault();

        fetchArtId(id);
        setIsUpdate(true);
        fetchFonts();
        setOpen(true);
    };

    const handleSubmitUpdate = async (e, id) => {
        e.preventDefault();


        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true);

            const formData = new FormData();
            formData.append('File', selectedFile);
            const attr = []
            selectedFontAttr.map(attrMapped => {
                attr.push({
                    name: attrMapped.name,
                    value: attrMapped.value
                })
            });

            const data = {
                code: code,
                title: title,
                font: font,
                attributes: attr
            };
            try {
                await updateArtId(id, data);
                setLoading(false);
                handleClose();
                handleClickSnackUpdate();
                fetchArts();
            } catch (err) {
                setLoading(false);
                console.log(err);
                setShow(true);
                let errorMsg = err.response.data.message.toString();
                let newErrorMsg = errorMsg.replaceAll(',', '\n\n');
                setMessage(newErrorMsg);
                setTimeout(function () {
                    setShow(false);
                    setMessage("");
                }, 7000);
            }
        }
    };

    const buildSkeletonList = () => (
        <>
            <ResourceListItem isLoading={loadingData} />
            <ResourceListItem isLoading={loadingData} />
            <ResourceListItem isLoading={loadingData} />
        </>
    );

    const buildArtsList = () =>
        searchString === ''
            ? arts.map((art) => (
                <ResourceListItem
                    key={art.id}
                    primary={art.title}
                    secondary={art.font}
                    onClickDelete={(event) => showConfirmDelete(art.id, event)}
                    onClickUpdate={(event) => handleUpdateArt(art.id, event)}
                    isLoading={loadingData}
                />
            ))
            : searchResult.map((art) => (
                <ResourceListItem
                    key={art.id}
                    primary={art.title}
                    secondary={art.font}
                    onClickDelete={(event) => showConfirmDelete(art.id, event)}
                    onClickUpdate={(event) => handleUpdateArt(art.id, event)}
                    isLoading={loadingData}
                />
            ));

    const updateSelectedFontAttr = (name, value) => {
        if (errors[name]) setErrors({
            ...errors,
            [name]: null
        })
        const updatedAttr = selectedFontAttr.map(attr => {
            if (attr.name === name) {
                return { ...attr, value: value };
            } else {
                return attr;
            }
        });
        return updatedAttr;
    }

    return (
        <Paper sx={{ maxWidth: 980, margin: 'auto', marginTop: 5, overflow: 'hidden' }}>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <Toolbar>
                    <Stack
                        direction="row"
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <TextField
                            fullWidth
                            placeholder="Pesquisar por obras"
                            InputProps={{
                                disableUnderline: true,
                                sx: { fontSize: 'default' },
                                startAdornment: <SearchIcon color="inherit" sx={{ display: 'block' }} />,
                            }}
                            variant="standard"
                            onChange={(event) => setSearchString(event.target.value)}
                        />
                        <Button
                            startIcon={<AddIcon />}
                            disabled={false}
                            size="small"
                            variant="contained"
                            onClick={handleClickOpen}
                            sx={{
                                whiteSpace: 'nowrap',
                                minWidth: 'max-content',
                            }}
                        >
                            Adicionar nova obra
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
            {!loadingData && arts.length === 0 && (
                <Typography variant="h6" sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                    {'Nenhuma Obra foi cadastrada ainda :('}
                </Typography>
            )}
            {!loadingData && arts.length !== 0 && searchResult.length === 0 && (
                <Typography variant="h6" sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                    {'Nenhuma Obra encontrada :('}
                </Typography>
            )}
            <List alignItems="center" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {loadingData ? buildSkeletonList() : buildArtsList()}
            </List>
            <Dialog
                onBackdropClick="false"
                open={open}
                fullWidth
                maxWidth={'lg'}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Button onClick={handleClose}>
                            <KeyboardArrowLeft />
                            Voltar
                        </Button>
                        {isUpdate ? "Editar obra" : "Cadastrar obra"}
                        {isUpdate ? (<Button variant="contained" onClick={event => handleSubmitUpdate(event, currentId)}>
                            Editar
                        </Button>) : (<Button variant="contained" onClick={handleSubmit}>
                            Cadastrar
                        </Button>)}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'auto',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            columns={12}
                            sx={{ mt: 2 }}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    name="code"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Código"
                                    sx={{ mt: 2 }}
                                    value={code}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => verifyErrorCode(e.target.value)}
                                    {...(errors.code && {
                                        error: true,
                                        helperText: errors.code,
                                    })}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    id="title"
                                    label="Título"
                                    name="title"
                                    sx={{ mt: 2 }}
                                    value={title}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => verifyTitleCode(e.target.value)}
                                    {...(errors.title && {
                                        error: true,
                                        helperText: errors.title,
                                    })}
                                />

                                <FormControl sx={{ mt: 2 }}
                                    {...(errors.font && {
                                        error: true,
                                        helperText: errors.font,
                                    })}>
                                    <InputLabel id="demo-simple-select-label" >Ficha catalográfica</InputLabel>
                                    <Select
                                        disabled={isUpdate}
                                        sx={{ width: 385 }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Possíveis Valores"
                                        name="font"
                                        value={capitalize(font)}
                                        onChange={(e) => setFontandSelectedFont(e.target.value, event.target.getAttribute('a-key'))}
                                    >
                                        {fonts.map(font => (
                                            <MenuItem a-key={font.id} key={font.id} value={font.name}>{font.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container
                                direction="column"
                                justifyContent="center"
                                alignItems="center" xs={4}>
                                <Collapse in={show} sx={{ mb: 2 }}>
                                    <Alert severity="error">
                                        <p>{message}</p>
                                    </Alert>
                                </Collapse>
                                <Typography>Adicionar a obra digitalizada</Typography>
                                <CloudUploadIcon color="primary" sx={{ fontSize: 150 }} />
                                <Typography variant='body2' color='error' hidden={!invalidFile}>Arquivo inválido! Por favor, escolha um arquivo válido.</Typography>
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="primary"
                                    label='Selecionar arquivo'>
                                    <input type="file" onChange={handleSelectFile} ref={fileRef} accept={allowedFileTypes.join(',')} />
                                </Button>
                                <Typography variant='body2' color='error'>*Apenas imagem e PDF</Typography>
                            </Grid>
                            <Grid container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                xs={8}
                                sx={{ mt: 2 }}
                            >
                                {isUpdate ?
                                    selectedFontAttr.map(attr => (
                                        <TextField
                                            key={attr.name}
                                            name={attr.name}
                                            required
                                            id={attr.name}
                                            label={attr.name}
                                            value={attr.value}
                                            sx={{ mt: 2, width: '45%' }}
                                            onChange={(e) => {
                                                const updatedAttr = updateSelectedFontAttr(attr.name, e.target.value);
                                                setSelectedFontAttr(updatedAttr);
                                            }}
                                            {...(errors[attr.name] && {
                                                error: true,
                                                helperText: errors[attr.name],
                                            })}
                                        />
                                    ))
                                    : selectedFontAttr.map(attr => (
                                        <TextField
                                            key={attr.name}
                                            name={attr.name}
                                            required
                                            id={attr.name}
                                            label={attr.name}

                                            sx={{ mt: 2, width: '45%' }}
                                            onChange={(e) => setField(attr.name, e.target.value)}
                                            {...(errors[attr.name] && {
                                                error: true,
                                                helperText: errors[attr.name],
                                            })}
                                        />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions />
            </Dialog>
            <Dialog
                onBackdropClick="false"
                open={openConfirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseConfirm}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Deseja excluir esta obra?"}</DialogTitle>
                <DialogContent />
                <DialogActions>
                    <Button onClick={handleCloseConfirm} >Cancelar</Button>
                    <Button onClick={(event) => handleDeleteArt(currentDeleteId, event)} color="error">Excluir</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Obra registrada com sucesso!
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackUpdate} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Obra atualizada com sucesso!
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackDelete} autoHideDuration={6000} onClose={handleCloseSnackDelete}>
                <Alert onClose={handleCloseSnackDelete} severity="success" sx={{ width: '100%' }}>
                    Obra apagada com sucesso!
                </Alert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Paper>
    )
}