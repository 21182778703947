import AssessmentIcon from '@mui/icons-material/Assessment';
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack, Button, Typography, AppBar, Toolbar } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { hasPermission, CONTEXTS, SCOPES } from '../../services/permissions';
import { TheNavBarTabs } from './TheNavBarTabs';

export const TheNavBar = ({ pathname }) => {
  const { authenticated, role, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openDashboardModal, setOpenDashboardModal] = useState(false);

  const handleCloseDashboardModal = () => {
    setOpenDashboardModal(false);
  }

  const handleOpenDashboardModal = () => {
    setOpenDashboardModal(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpen(false);
    logout();
  };

  const handleLogoClick = () => {
    window.location.href = "/";
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Stack
          direction="row"
          onClick={handleLogoClick}
          spacing={2}
          sx={{
            cursor: 'pointer',
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/cedomca.jpeg`} alt='logo' />
          <Typography
            variant="h6"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            CEDOMCA
          </Typography>
        </Stack>
        {authenticated ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              flexGrow: 1,
            }}
          >
            <TheNavBarTabs pathname={pathname} />
          </Stack>
        ) : null}
        {authenticated && hasPermission(role, CONTEXTS.report, SCOPES.canView) ? (
          <Button color="inherit" onClick={handleOpenDashboardModal} startIcon={<AssessmentIcon />}>Relatório</Button>
        ) : null}
        {authenticated && hasPermission(role, CONTEXTS.report, SCOPES.canView) ? (
          <Dashboard open={openDashboardModal} onClose={handleCloseDashboardModal} />
        ) : null}
        {authenticated ? (
          <Button color="inherit" onClick={handleClickOpen} startIcon={<LogoutIcon />}>
            Sair
          </Button>
        ) : null}
      </Toolbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sair"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que deseja sair?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleLogout}>OK</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};
