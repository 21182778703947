import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { Button,  Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { getArts, getArtsByAttributes } from '../../../services/api';
import AttributeSelectAndValue from './AttributeSelectAndValue';

export const FilterBy = ({ 
    fonts, 
    searchTitleString, 
    setSearchTitleString, 
    setArts,
    searchCodeString,
    setSearchCodeString
}) => {
    const resolveAttributesToObjArray = (fontIndex) => fonts[fontIndex].attributes.map((attribute) => ({
        name: attribute.name,
        value: '',
        isSelected: false
    }));

    const [selectedFontIndex, setSelectedFontIndex] = useState(null);
    const [filterByFont, setFilterByFont] = useState(false);

    const [orderOfSelectedAttributes, setOrderOfSelectedAttributes] = useState([]);
    const [attributesAndValues, setAttributesAndValues] = useState(resolveAttributesToObjArray(0));

    useEffect(() => {
        if (orderOfSelectedAttributes.length === 0) {
            if(filterByFont) {
                getArts(fonts[selectedFontIndex ?? 0].name).then((fetchedArts) => setArts(fetchedArts.data));
            } else {
                getArts().then((fetchedArts) => setArts(fetchedArts.data));
            }
        }
    }, [orderOfSelectedAttributes]);
    
    useEffect(() => {
        if (selectedFontIndex !== null) {
            getArts(fonts[selectedFontIndex].name).then((fetchedArts) => setArts(fetchedArts.data));
        } else {
            getArts().then((fetchedArts) => setArts(fetchedArts.data));
        }
    }, [selectedFontIndex]);

    useEffect(() => {
        if(filterByFont) {
            if (selectedFontIndex === null) {
                setSelectedFontIndex(0);
            } else {
                getArts(fonts[selectedFontIndex].name).then((fetchedArts) => setArts(fetchedArts.data));
            }
        } else {
            getArts().then((fetchedArts) => setArts(fetchedArts.data));
        }
    }, [filterByFont]);


    const handleSelectFontChange = (event) => {
        const fontIndex = event.target.value;

        setSelectedFontIndex(fontIndex);
        setSearchTitleString('');
        setSearchCodeString('');
        setAttributesAndValues(resolveAttributesToObjArray(fontIndex));
        setOrderOfSelectedAttributes([]);
    }

    const appendNewAttributeToFilter = () => {
        const firstNotSelected = attributesAndValues.findIndex((attributeAndValue) => !attributeAndValue.isSelected);
        if (firstNotSelected === -1) return;

        const copyOfAttributesAndValues = [...attributesAndValues];
        const copyOfOrderOfSelectedAttributes = [...orderOfSelectedAttributes];

        copyOfAttributesAndValues[firstNotSelected].isSelected = true;
        copyOfOrderOfSelectedAttributes.push(firstNotSelected);
        
        setAttributesAndValues(copyOfAttributesAndValues);
        setOrderOfSelectedAttributes(copyOfOrderOfSelectedAttributes);
    }

    const handleSearchByAttributes = () => {
        getArtsByAttributes(fonts[selectedFontIndex].name, attributesAndValues).then((fetchedArts) => setArts(fetchedArts.data));
    }

    const handleCleanFilters = () => {
        setAttributesAndValues(resolveAttributesToObjArray(selectedFontIndex));
        setOrderOfSelectedAttributes([]);

        if (filterByFont) {
            getArts(fonts[selectedFontIndex].name).then((fetchedArts) => setArts(fetchedArts.data));
        } else {
            getArts().then((fetchedArts) => setArts(fetchedArts.data));
        }
    }

    return (
        <Grid item xs={12} lg={4}>
            <Typography>Filtrar por</Typography>
            <Stack direction='row' marginLeft={2} marginTop={2}>
                <FormControl disabled={!filterByFont} fullWidth>
                    <InputLabel shrink={true} id="select-fonts">Tipo de ficha catalográfica</InputLabel>
                    <Select
                        notched={true}
                        labelId="select-fonts"
                        label="Tipo de ficha catalográfica"
                        value={selectedFontIndex ?? 0}
                        onChange={(event) => handleSelectFontChange(event)}
                    >
                        {
                            fonts && fonts.map((font, index) => (
                                <MenuItem key={index} value={index}>{font.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Checkbox checked={filterByFont} onChange={() => setFilterByFont(!filterByFont)}/>
            </Stack>
            <Stack direction='column' margin={2}>
                <TextField 
                    label='Título da obra' 
                    variant='outlined'
                    value={searchTitleString}
                    onChange={(event) => setSearchTitleString(event.target.value)}
                    sx={{
                        marginBottom: 2
                    }}
                />
                <TextField 
                    label='Código da obra' 
                    variant='outlined'
                    value={searchCodeString}
                    onChange={(event) => setSearchCodeString(event.target.value)}
                />
            </Stack>
            <Typography>Características</Typography>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <Button
                        variant='contained'
                        disabled={!filterByFont}
                        onClick={appendNewAttributeToFilter}
                        startIcon={<AddIcon />}
                        fullWidth
                        sx={{
                            height: '100%'
                        }}
                    >
                        Nova característica
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Button
                        variant='contained'
                        disabled={attributesAndValues.every((attributeAndValue) => !attributeAndValue.isSelected)}
                        startIcon={<RemoveCircleOutlineIcon />}
                        onClick={handleCleanFilters}
                        color='error'
                        fullWidth
                        sx={{
                            height: '100%'
                        }}
                    >
                        Remover características
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Button
                        variant='contained'
                        disabled={
                            attributesAndValues.every((attributeAndValue) => !attributeAndValue.isSelected) ||
                            attributesAndValues.some((attributeAndValue) => attributeAndValue.value === '' && attributeAndValue.isSelected)
                        }
                        startIcon={<SearchIcon />}
                        onClick={handleSearchByAttributes}
                        color='success'
                        fullWidth
                        sx={{
                            height: '100%'
                        }}
                    >
                        Pesquisar
                    </Button>
                </Grid>
            </Grid>
            <Stack
                direction='column'
                sx={{
                    maxHeight: '450px',
                    overflow: 'auto'
                }}
            >
                {
                    orderOfSelectedAttributes.map((selectedAttributeIndex, index) => (
                        attributesAndValues[selectedAttributeIndex].isSelected && 
                        <AttributeSelectAndValue 
                            key={index}
                            position={index}
                            selectedAttributeIndex={selectedAttributeIndex}
                            attributesAndValues={attributesAndValues}
                            setAttributesAndValues={setAttributesAndValues}
                            orderOfSelectedAttributes={orderOfSelectedAttributes}
                            setOrderOfSelectedAttributes={setOrderOfSelectedAttributes}
                        />
                    ))
                }
            </Stack>
        </Grid>
    );
}