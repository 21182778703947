import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, capitalize, Grid,  IconButton,  Skeleton,    Stack,    Typography } from "@mui/material";
import axios from 'axios';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import placeholder from '../../../assets/no-image-placeholder.png'
import { getArtId } from "../../../services/api";
import { AttributeItem } from "./AttributeItem";

export const ArtData = (props) => {
    const { artId } = props;

    const [art, setArt] = useState({});
    const [isFetchingData, setIsFetchingData] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getArtId(artId).then((response) => {
            setArt(response.data);
            setIsFetchingData(false);
        });
    }, []);

    const buildSkeletonList = () => (
        <Stack direction='column' spacing={2}>
            <Skeleton variant='rectangular' />
            <Skeleton variant='rectangular' />
            <Skeleton variant='rectangular' />
        </Stack>
    );

    const buildImage = () => {
        const isPdf = art.filePath?.match(/\.pdf$/i);
        if (art.filePath === undefined || isPdf) {
            return (<img src={placeholder} alt='Imagem da obra' width='100%' height='450px'/>);
        }

        return (<img src={art.filePath} alt='Imagem da obra' width='100%' height='450px'/>);
    }

    const handleDownloadFile = async () => {
        const res = await axios.get(art.filePath, {responseType: 'blob'});
        const file = res.data;
        const fileType = file.type.split('/')[1];
        console.log(file)
        const objUrl = URL.createObjectURL(file)
        const link = document.createElement('a');
        link.href = objUrl;
        link.setAttribute('download', `obra.${fileType}`);
        
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
    
    return (
        <Grid container>
            <Grid item xs={2}>
                <IconButton onClick={() => navigate('/arts/search')}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid item xs={10}>
                <Typography
                    variant='h3'
                    sx={{
                        textAlign: 'center',
                        marginBottom: '1rem',
                    }}
                >
                    {isFetchingData ? <Skeleton /> : capitalize(art.title)}
                </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                {
                    isFetchingData 
                    ? 
                        <Skeleton variant='rectangular' width='90%' height='450px' /> 
                    : 
                        buildImage()
                }
                <Box
                    sx={{
                        paddingTop: 2,
                        alignContent: ''
                    }}
                >
                    <Button
                        disabled={art.filePath === undefined}
                        fullWidth
                        variant='contained' 
                        startIcon={<DownloadIcon />}
                        onClick={handleDownloadFile}
                    >
                        Baixar arquivo
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Box marginLeft={3}>
                    <Typography 
                        variant='h4'
                        marginTop={2}
                        marginBottom={1}
                    >
                        Dados gerais
                    </Typography>
                    {
                        isFetchingData 
                        ? 
                            buildSkeletonList() 
                        : 
                            <>
                                <AttributeItem 
                                    isFetchingData
                                    name='Código' 
                                    value={art.code} 
                                />
                                <AttributeItem 
                                    isFetchingData
                                    name='Ficha Catalográfica' 
                                    value={art.font} 
                                />
                            </>
                    }
                    <Typography 
                        variant='h4'
                        marginTop={2}
                        marginBottom={1}
                    >
                        Dados característicos
                    </Typography>
                    <Grid container>
                        {
                            isFetchingData
                            ? 
                                buildSkeletonList()
                            :
                                art.attributes.map((attribute, index) => (
                                    <AttributeItem 
                                        key={index}
                                        isFetchingData
                                        name={attribute.name} 
                                        value={attribute.value} 
                                    />
                                ))
                        }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}