import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ArtsListItem } from "./ArtsListItem";

export const ArtsList = (props) => {
    const { arts, searchTitleString, searchCodeString } = props;

    const [filteredArts, setFilteredArts] = useState([]);

    useEffect(() => {
        const filteredArts = arts.filter((art) =>
            art.title.toLowerCase().includes(searchTitleString.toLowerCase()) &&
            art.code.toLowerCase().includes(searchCodeString.toLowerCase()),
        );

        setFilteredArts(filteredArts);
    }, [searchTitleString, searchCodeString]);

    const handleSearch = () => {
        const artsToShow = searchTitleString === '' && searchCodeString === '' ? arts : filteredArts;

        if (artsToShow.length === 0) {
            return (
                <Typography 
                    variant="h6" 
                    sx={{ my: 5, mx: 2 }} 
                    color="text.secondary" 
                    align="center"
                >
                    {'Nenhuma obra encontrada :('}
                </Typography>
            )
        }

        return (
            artsToShow.map((art, index) => (
                <ArtsListItem
                    key={index}
                    art={art}
                />
            ))
        );
    }

    return (
        <Grid item xs={12} lg={8} direction='column'>
            <Typography 
                variant='h4'
                sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                Lista de obras
            </Typography>
            {
                handleSearch()
            }
        </Grid>
    );
}