import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Comments } from "../Comment/Comments";
import { ArtData } from "./ArtData";

export const Art = () => {
    const { artId } = useParams();

    return (
        <Grid container
            spacing={5}
            sx={{
                padding: '1.5rem',
            }}
        >
            <Grid item xs={12} lg={8.5}>
                <ArtData artId={artId} />
            </Grid>
            <Grid item xs={12} lg={3.5}>
                <Comments artId={artId} />
            </Grid>
        </Grid>
    );
}