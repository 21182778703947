import { Avatar, Grid, Typography } from "@mui/material";

export default function Colaborators() {
  return (
    <Grid container
      direction="row"
      alignItems="flex-start"
      sx={{ mt: 2, mb: 4, width: '90%' }}
    >
      <Grid item sm={12}>
        <Typography sx={{ mb: 2 }} variant="h6">Responsáveis:</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/lucyanne.jpeg`} alt='lucyanne' />
        </Avatar>
        <Typography>Lucyanne de Melo Afonso</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/joao.jpeg`} alt='joao' />
        </Avatar>
        <Typography>João Gustavo Kienen</Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography sx={{ mb: 2, mt: 4 }} variant="h6">Apoio operacional:</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/fernando_alves.jpeg`} alt='fernando' />
        </Avatar>
        <Typography>Fernando Alves Matos</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/rosiel_mendonca.jpg`} alt='rosiel' />
        </Avatar>
        <Typography>Rosiel Mendonça</Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography sx={{ mb: 2, mt: 4 }} variant="h6">Alunas(os) pesquisadoras(os):</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/danielle.jpeg`} alt='danielle' />
        </Avatar>
        <Typography>Danielle Colares</Typography>
      </Grid>
      <Grid item sm={4}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/darc_anne.jpeg`} alt='darc' />
        </Avatar>
        <Typography>Darc Anne Ferreira de Vasconcelos</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/ester_gama.jpeg`} alt='ester' />
        </Avatar>
        <Typography>Ester Gama de Albuquerque</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/joao_pedro.jpeg`} alt='joao' />
        </Avatar>
        <Typography>João Pedro Soares Santiago</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/joelina_sulamita.jpeg`} alt='joelina' />
        </Avatar>
        <Typography>Joelina Sulamita Leite Nunes</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/julieni_soares.jpeg`} alt='fernando' />
        </Avatar>
        <Typography>Julieni Soares Galvão</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/laura_ferreira.jpeg`} alt='laura' />
        </Avatar>
        <Typography>Laura Ferreira Andrade</Typography>
      </Grid>
      <Grid item sm={4} sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 100, height: 100 }}
        >
          <img src={`${process.env.PUBLIC_URL}/sandy_heloisa.jpeg`} alt='sandy' />
        </Avatar>
        <Typography>Sandy Heloisa de Lima Pereira</Typography>
      </Grid>
    </Grid>
  )
}