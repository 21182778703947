import { capitalize,  Divider,   Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import placeholder from '../../../assets/no-image-placeholder.png'

export const ArtsListItem = ({ art }) => {
    const navigate = useNavigate();

    return (
        <Box marginBottom={2}>
            <Grid container 
                onClick={() => navigate(`/arts/${art.id}`)} 
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Grid item xs={4} md={2}>
                    {
                        art.filePath !== undefined 
                        ? 
                            <img src={art.filePath} alt='Imagem da obra' width='100%' height='150px'/>
                        :
                            <img src={placeholder} alt='Imagem da obra' width='100%' height='150px'/>
                    }
                </Grid>
                <Grid item xs={8} md={2} paddingLeft={2}>
                    <Typography variant='h5'>{capitalize(art.title)}</Typography>
                    <Typography variant='h6'>{capitalize(art.font)}</Typography>
                </Grid>
            </Grid>
            <Divider />
        </Box>
    )
}