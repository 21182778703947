import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {  getArts, getFonts } from "../../../services/api";
import { ArtsList } from "./ArtsList";
import { FilterBy } from "./FilterBy";

export const SearchArtwork = () => {
    const [fonts, setFonts] = useState([]);
    const [arts, setArts] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [searchTitleString, setSearchTitleString] = useState('');
    const [searchCodeString, setSearchCodeString] = useState('')

    useEffect(() => {
        document.title = 'CEDOMCA | Pesquisar obras'

        getFonts().then((fetchedFonts) => {
            setFonts(fetchedFonts.data);
        });
    }, []);

    useEffect(() => {
        if (fonts.length > 0) {
            getArts().then((fetchedArts) => {
                setArts(fetchedArts.data);
                setIsFetchingData(false);
            });
        }
    }, [fonts]);

    return (
        <Box 
            sx={{
                padding: '1rem',
            }}
        >
            {
                !isFetchingData && 
                <Grid container spacing={5}>
                    <FilterBy 
                        fonts={fonts} 
                        searchTitleString={searchTitleString} 
                        setSearchTitleString={setSearchTitleString} 
                        setArts={setArts}
                        searchCodeString={searchCodeString}
                        setSearchCodeString={setSearchCodeString}
                    />
                    <ArtsList
                        arts={arts}
                        searchTitleString={searchTitleString}
                        searchCodeString={searchCodeString}
                    />
                </Grid>
            }
        </Box>
    );
}