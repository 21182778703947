import { Grid, Typography } from '@mui/material';
import Colaborators from './Colaborators';


export default function AboutUs() {
    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Typography variant="h2" gutterBottom sx={{ mt: 4, mb: 0 }} textAlign='center'>
                Acervo de Artes CEDOMCA
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                Bem-vindo ao Laboratório e Centro de Documentação e Memória da Cultura na Amazônia (CEDOMCA), o acervo da Faculdade de Artes da Universidade Federal do Amazonas (UFAM). Nós somos uma equipe dedicada de professores e estudantes que trabalham juntos para preservar e compartilhar a rica história da arte na região amazônica.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                O Laboratório e Centro de Documentação e Memória da Cultura na Amazônia (CEDOMCA) foi criado em 23 de janeiro de 2018, na Faculdade de Artes da Universidade Federal do Amazonas, tendo como finalidades: 1) a salvaguarda da memória da cultura na Amazônia, presentificada em “documentos” que comporão o seu acervo; 2) a acessibilidade do seu acervo para o público vasto - pesquisadores, estudantes e público em geral e; 3) o fomento do ensino, de pesquisas, ações de extensão e exposições a partir de seu acervo, além da colaboração nas demais exposições e ações cujo acervo possui afinidades.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                O CEDOMCA tem parceria multidisciplinar com as Ciências da Informação, com os procedimentos da Arquivologia, com a Museologia e com a Biblioteconomia. Estes campos contribuem para as ações de trabalho mais técnico de tratamento e guarda de documentos.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                Além do mais, o CEDOMCA está preocupado com a salvaguarda dos acervos referentes à cultura amazônica, a fim de desenvolver formas de uso do conhecimento adquirido pelos conjuntos documentais e produzir novas ações e conteúdos a ele relacionados.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                O acervo do CEDOMCA é constituído, a princípio, por um conjunto de livros, bibliografias, LPs oriundos do extinto Conservatório Musical “Joaquim Franco” (1965-1978); dos livros pertencentes a biblioteca particular da artista Bernadete Andrade (1953-2007); cópias de partituras de Arnaldo Rebello (1905-1987) e Lindalva Cruz (1908-2005); dissertações e teses temáticas da Cultura Amazônica; coleção de LPs temáticos da cultura dos povos tradicionais do Brasil; gravações em áudio de cantos, danças e rituais dos povos da floresta, entre outros materiais.
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ width: '90%', mt: 2 }} align='justify'>
                Se você é um artista, pesquisador, estudante, professor ou simplesmente alguém interessado na arte, convidamos você a navegar pelo nosso site e visitar nosso acervo pessoalmente. Estamos ansiosos para compartilhar nossa paixão por arte com você.
            </Typography>
            <Colaborators />
        </Grid>

    )

}
